<template>
    <div class="reviews">
        <v-row style="max-width: 2400px" class="mx-auto">
            <v-col cols="12">
                <v-card class="mx-auto elevation-6">
                    <v-toolbar height="50" color="indigo darken-6" dark flat>
                        <v-btn-toggle v-model="selectTab" tile group mandatory dense>
                            <v-btn value="inner">
                                <v-icon>mdi-comment-quote</v-icon>{{ `Отзывы Darall (${reviews.length})` }}
                            </v-btn>

                            <v-btn value="parsed">
                                <v-icon>mdi-comment-search</v-icon>{{ `Внешние отзовики${selectParsePoint ? ' - ' + selectParsePoint.name + ' (' + filterReviewsParsed.length + ')' : ''}` }}
                            </v-btn>
                        </v-btn-toggle>

                        <v-spacer></v-spacer>

                        <div class="d-flex align-center flex-md-row flex-column">
                            <!-- <div class="d-flex align-center flex-md-row flex-column" v-if="selectTab=='inner'"> -->
                            <v-btn-toggle v-model="calendar" tile group mandatory dense>
                                <v-btn value="left" @click="onClickToday" dark active-class="light"> <v-icon>mdi-calendar</v-icon> {{ $t('reviews.buttons.today') }} </v-btn>

                                <v-btn value="center" @click="onClickYesterday" dark active-class="light"> <v-icon>mdi-calendar-arrow-left</v-icon> {{ $t('reviews.buttons.yesterday') }} </v-btn>

                                <v-btn value="right" @click="onClickMonth" dark active-class="light"> <v-icon>mdi-calendar-month</v-icon> {{ $t('reviews.buttons.month') }} </v-btn>

                                <v-menu v-model="menu_calendar" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text class="menu-button" v-on="on" dark active-class="light">
                                            <v-icon class="mr-1">mdi-calendar-multiple</v-icon>
                                            {{ $t('reviews.buttons.range') }}
                                        </v-btn>
                                    </template>
                                    <div @keydown.esc="closeCalendar" tabindex="0">
                                        <v-date-picker range v-model="dates" locale="ru-ru" selected-items-text="Выбран период" :first-day-of-week="1" @change="onClickChangeDate"> </v-date-picker>
                                    </div>
                                </v-menu>
                            </v-btn-toggle>

                            <div v-if="selectTab == 'inner'" class="d-flex">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span class="tooltip-text" v-bind="attrs" v-on="on">
                                            <v-btn @click="onClickDownloadData" class="mx-4 px-2" tile icon rounded>
                                                <v-img src="../../assets/images/excel-300.png" width="40"></v-img>
                                            </v-btn>
                                        </span>
                                    </template>
                                    <span>Выгрузка данных в таблицу</span>
                                </v-tooltip>
                            </div>

                            <v-btn icon @click="onClickUpdate" :loading="loading" dark active-class="light">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </div>
                    </v-toolbar>

                    <div class="pos-rel" v-if="loading">
                        <v-progress-linear indeterminate absolute top color="deep-purple accent-4"></v-progress-linear>
                    </div>

                    <div v-if="selectTab == 'inner'">
                        <v-card-title class="pt-0 pb-2 d-flex">
                            <v-row dense justify="end">
                                <v-col cols="3">
                                    <v-select class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" :items="quick_filters" v-model="quick_filter" label="Быстрые фильтры" @change="filters" hide-details></v-select>
                                </v-col>
                                <v-col cols="3" v-if="!userIsPoint">
                                    <v-select class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" @change="filters" :items="filter_points" name="point" item-text="name" item-value="id" v-model="filter_point" label="По точке" hide-details></v-select>
                                </v-col>
                                <v-col cols="3">
                                    <v-select class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" @change="filters" :items="filter_couriers" name="courier" item-text="name" item-value="tid" v-model="filter_courier" label="По курьеру" hide-details></v-select>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="search" class="mx-2 flex-grow-0" style="width: 220px" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details @input="filters"></v-text-field>
                                </v-col>
                                <v-col cols="8" v-if="reviews.length">
                                    <div v-if="!loading" class="d-flex pt-3">
                                        <p class="mb-0">Средняя оценка:</p>
                                        <v-rating background-color="grey lighten-1" color="warning" empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full" length="5" size="28" :value="reviewsStars" readonly dense half-increments></v-rating>
                                        <p class="mb-0" style="margin: 2px 0">{{ Math.floor(reviewsStars * 100) / 100 }}</p>
                                    </div>
                                </v-col>
                                <v-col cols="3" align-self="end">
                                    <v-switch v-model="tabularView" label="Табличный вид" @change="filters"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-row v-if="hashtags.length" class="mx-6 mb-4">
                            <span class="mt-2 mr-3">По тегам:</span>
                            
                            <v-chip-group multiple column>
                                <v-chip
                                    v-for="tag in hashtags"
                                    outlined
                                    small
                                    :key="tag.name"
                                    :class="tag.isSelected ? 'indigo indigo--text' : ''"
                                    @click="filters(tag)"
                                >
                                    {{ tag.name }}
                                </v-chip>
                            </v-chip-group>
                        </v-row>

                        <v-card v-if="tabularView">
                            <v-data-table
                                :mobile-breakpoint="0"
                                dense
                                no-data-text="Нет данных"
                                no-results-text="Нет данных, попробуйте изменить запрос"
                                :footer-props="{
                                    pageText: '{0} - {1} из {2}',
                                    itemsPerPageText: 'Показывать на странице',
                                    itemsPerPageAllText: 'все',
                                    showFirstLastPage: true,
                                    itemsPerPageOptions: [25, 50, 100, -1],
                                }"
                                :headers="headers"
                                :search="search"
                                :items="reviews"
                                item-key="index"
                                item-class="rowItem"
                                :loading="loading"
                                :loading-text="$t('common.loading')"
                                @click:row="onClickReview($event)"
                            >
                                <template v-slot:[`item.name`]="{ item }">
                                    <b>{{ item.name }}</b>
                                </template>

                                <template v-slot:[`item.guest`]="{ item }">
                                    <span style="min-width: 180px">
                                        <v-menu bottom color="rgba(255,255,255, 0)" open-on-hover :close-on-content-click="false" offset-y close-delay="100" open-delay="200">
                                            <template v-slot:activator="{ on, attrs }">
                                                <td v-bind="attrs" v-on="on">
                                                    <router-link v-if="item.guest_phone" :to="{ name: 'guests_phone', params: { phone: item.guest_phone } }" class="text-decoration-none" style="color: inherit">
                                                        <span class="py-2 tooltip-text" :class="loading ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'" @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span>
                                                    </router-link>
                                                    <span v-else class="py-2 tooltip-text">{{ getGuestStr(item.guest) }}</span>
                                                </td>
                                            </template>
                                            <v-card class="pa-2 menu_text" max-width="800px" v-if="item.guest_item">
                                                <div>
                                                    Телефон: <b v-if="item.guest_phone !== null">{{ item.guest_phone }}</b> <b v-else>Номер не указан</b>
                                                </div>
                                                <div>
                                                    Имя: <b v-if="item.guest_name !== null">{{ item.guest_name }}</b>
                                                    <b v-else>Имя не указано</b>
                                                </div>
                                                <div>
                                                    Первый визит: <b>{{ timeToString(item.guest_item.date_create) }}</b>
                                                </div>
                                                <div>
                                                    Последний визит: <b>{{ timeToString(item.guest_item.date_visit) }}</b>
                                                </div>
                                                <v-divider class="mt-1 mb-1"></v-divider>
                                                <div v-if="($store.state.userIsPoint && $store.state.user.point_id) || filter_point">
                                                    <div>
                                                        Заказы точки: <b>{{ item.guest_item.count }}</b>
                                                    </div>
                                                    <div>
                                                        Чек у точки: <b>{{ item.guest_item.cost }}</b>
                                                    </div>
                                                    <div>
                                                        Заказы у других: <b>{{ item.guest_item.other_count }}</b>
                                                    </div>
                                                    <div>
                                                        Чек у других: <b>{{ item.guest_item.other_cost }} </b>
                                                    </div>
                                                </div>
                                                <div>
                                                    Заказы всего: <b>{{ item.guest_item.order_count }}</b>
                                                </div>
                                                <div v-if="!(($store.state.userIsPoint && $store.state.user.point_id) || filter_point)">
                                                    Чек всего: <b>{{ item.guest_item.pay_cost }} </b>
                                                </div>
                                                <div v-if="!$store.state.userIsPoint">
                                                    Точки: <b>{{ item.guest_item.points }}</b>
                                                </div>
                                            </v-card>
                                        </v-menu>
                                    </span>
                                </template>

                                <template v-slot:[`item.value`]="{ item }">
                                    <v-rating background-color="grey lighten-1" color="warning" empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full" length="5" size="14" :value="item.value" dense readonly></v-rating>
                                </template>

                                <template v-slot:[`item.review_text`]="{ item }">
                                    <v-menu bottom color="rgba(255,255,255, 0)" open-on-hover :close-on-content-click="false" offset-y close-delay="100" open-delay="200">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                {{ item.review_text && item.review_text.length > 24 ? item.review_text.substr(0, 24) + '...' : item.review_text }}
                                            </span>
                                        </template>
                                        <v-card class="pa-2 menu_text" max-width="800px">
                                            <div>
                                                Гость: <b v-if="item.guest_name !== null">{{ item.guest_name }}</b> <b v-else>Имя не указано</b>
                                            </div>
                                            <div>
                                                Номер гостя: <b v-if="item.guest_phone !== null">{{ item.guest_phone }}</b> <b v-else>Номер не указан</b>
                                            </div>
                                            <v-rating background-color="grey lighten-1" color="warning" empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full" length="5" size="15" :value="item.value" dense readonly> </v-rating>
                                            <div>
                                                Отзыв: <b>{{ item.review_text }}</b>
                                            </div>
                                            <v-divider class="mt-1 mb-1"></v-divider>
                                            <div>
                                                Точка: <b>{{ item.point_id ? pointNameGet(item.point_id) : item.order_name }}</b>
                                            </div>
                                            <div>
                                                Курьер: <b>{{ userIsPoint ? item.courier_name.split(' ')[0] : item.courier_name }}</b>
                                            </div>
                                            <div>
                                                <span class="mr-2"
                                                    >Время доставки: <b>{{ getStringToDate(item.date_complete, 'DD MMM HH:mm') }}</b></span
                                                >
                                                <v-chip class="px-1" :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label text-color="white">
                                                    {{ timeDiff(new Date(item.date_complete), new Date(item.date_delivery)) }}
                                                </v-chip>
                                            </div>
                                        </v-card>
                                    </v-menu>
                                </template>

                                <template v-slot:[`item.point_name`]="{ item }">
                                    <div class="text-no-wrap">
                                        <b>{{ pointNameGet(item.point_id).length > 20 ? pointNameGet(item.point_id).substr(0, 20) + '...' : pointNameGet(item.point_id) }}</b>
                                    </div>
                                </template>

                                <template v-slot:[`item.platform`]="{ item }">
                                    <img v-if="item.platform || item.value < 5" class="mt-1" height="24" alt="platform" :src="iconPlatformSource(item.platform)" />
                                    <span v-if="!item.platform && item.value >= 5"> Без перехода </span>
                                </template>

                                <template v-slot:[`item.courier_name`]="{ item }">
                                    <div class="text-no-wrap">
                                        <v-avatar size="24" class="mr-2" v-if="item.courier_name != '--'">
                                            <img alt="courier" :src="`${config.api.delivery + 'user_avatar?tid=' + item.courier_tid}`" />
                                        </v-avatar>
                                        <b v-if="userIsPoint">{{ item.courier_name.split(' ')[0] }}</b>
                                        <b v-else>{{ item.courier_name.length > 20 ? item.courier_name.substr(0, 20) + '...' : item.courier_name }}</b>
                                    </div>
                                </template>

                                <template v-slot:[`item.respond`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                <v-icon :style="{ color: respondColorHtml(item) }">{{ respondIcon(item) }}</v-icon>
                                            </span>
                                        </template>
                                        <span>{{ respondTooltip(item) }}</span>
                                    </v-tooltip>
                                </template>

                                <template v-slot:[`item.date_vote`]="{ item }">
                                    {{ timeToString(item.date_vote) }}
                                </template>

                                <template v-slot:[`item.date_complete`]="{ item }">
                                    <span class="mr-2">{{ timeToString(item.date_complete) }}</span>
                                    <v-chip v-if="item.date_complete" class="px-1" :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label text-color="white">
                                        {{ timeDiff(item.date_complete, item.date_delivery) }}
                                    </v-chip>
                                </template>

                                <template v-slot:[`item.order_name`]="{ item }">
                                    <v-menu bottom color="rgba(255,255,255, 0)" open-on-hover :close-on-content-click="false" offset-y close-delay="100" open-delay="200">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                {{ item.order_name }}
                                            </span>
                                        </template>
                                        <v-card class="pa-2 menu_text" max-width="800px">
                                            <div>
                                                Название: <b>{{ item.order_name }} </b>
                                                <v-avatar size="20">
                                                    <img alt="source" :src="iconSource(item)" />
                                                </v-avatar>
                                            </div>
                                            <div>
                                                Точка: <b>{{ item.point_id ? pointNameGet(item.point_id) : item.order_name }}</b>
                                            </div>
                                            <div>
                                                Курьер: <b>{{ userIsPoint ? item.courier_name.split(' ')[0] : item.courier_name }}</b>
                                            </div>
                                            <v-divider class="mt-1 mb-1"></v-divider>
                                            <div>
                                                Забран: <b>{{ timeToString(item.date_take) }} </b>
                                                <v-chip v-if="item.date_take" class="px-1 mb-1" :color="timeColorHtml(item.date_take, item.date_ready)" x-small label text-color="white">
                                                    {{ timeDiff(item.date_take, item.date_ready) }}
                                                </v-chip>
                                            </div>
                                            <div>
                                                Доставлен: <b>{{ timeToString(item.date_complete) }}</b>
                                                <v-chip v-if="item.date_complete" class="px-1 mb-1" :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label text-color="white">
                                                    {{ timeDiff(item.date_complete, item.date_delivery) }}
                                                </v-chip>
                                            </div>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-data-table>
                        </v-card>

                        <v-card v-else elevation="0" id="reviews-list">
                            <v-row justify="center" height="auto" max-height="600px" min-height="60px" width="100%">
                                <v-col cols="2" align-self="center" v-if="reviews.length === 0 && !loading">
                                    <div>Нет данных</div>
                                </v-col>
                                <v-col v-for="item in 6" :key="item" v-show="loading" cols="10">
                                    <v-card elevation="2">
                                        <v-skeleton-loader :loading="loading" type="list-item-avatar-three-line"></v-skeleton-loader>
                                    </v-card>
                                </v-col>
                                <v-col v-for="item in reviewsBeforeScroll" v-show="!loading" cols="10" :key="item.id" align-self="center">
                                    <v-card elevation="2">
                                        <v-card-text>
                                            <v-row dense>
                                                <v-col cols="2">
                                                    <v-menu bottom color="rgba(255,255,255, 0)" open-on-hover :close-on-content-click="false" offset-y close-delay="100" open-delay="200">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div v-bind="attrs" v-on="on">
                                                                <v-icon size="50px">mdi-account-circle</v-icon>
                                                                <div>
                                                                    <b v-if="item.guest_name !== null">{{ item.guest_name }}</b> <b v-else>Имя не указано</b>
                                                                </div>
                                                                <div>
                                                                    <b v-if="item.guest_phone !== null">{{ item.guest_phone }}</b> <b v-else>Номер не указан</b>
                                                                </div>
                                                                <div>{{ timeToString(item.date_vote) }}</div>
                                                            </div>
                                                        </template>
                                                        <v-card class="pa-2 menu_text" max-width="800px" v-if="item.guest_item">
                                                            <div>
                                                                Телефон: <b v-if="item.guest_phone !== null">{{ item.guest_phone }}</b> <b v-else>Номер не указан</b>
                                                            </div>
                                                            <div>
                                                                Имя: <b v-if="item.guest_name !== null">{{ item.guest_name }}</b> <b v-else>Имя не указано</b>
                                                            </div>
                                                            <div>
                                                                Первый визит: <b>{{ timeToString(item.guest_item.date_create) }}</b>
                                                            </div>
                                                            <div>
                                                                Последний визит: <b>{{ timeToString(item.guest_item.date_visit) }}</b>
                                                            </div>
                                                            <v-divider class="mt-1 mb-1"></v-divider>
                                                            <div v-if="($store.state.userIsPoint && $store.state.user.point_id) || filter_point">
                                                                <div>
                                                                    Заказы точки: <b>{{ item.guest_item.count }}</b>
                                                                </div>
                                                                <div>
                                                                    Чек у точки: <b>{{ item.guest_item.cost }}</b>
                                                                </div>
                                                                <div>
                                                                    Заказы у других: <b>{{ item.guest_item.other_count }}</b>
                                                                </div>
                                                                <div>
                                                                    Чек у других: <b>{{ item.guest_item.other_cost }} </b>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                Заказы всего: <b>{{ item.guest_item.order_count }}</b>
                                                            </div>
                                                            <div v-if="!(($store.state.userIsPoint && $store.state.user.point_id) || filter_point)">
                                                                Чек всего: <b>{{ item.guest_item.pay_cost }} </b>
                                                            </div>
                                                            <div v-if="!$store.state.userIsPoint">
                                                                Точки: <b>{{ item.guest_item.points }}</b>
                                                            </div>
                                                        </v-card>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="7" align-self="start">
                                                    <v-rating background-color="grey lighten-1" color="warning" empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full" length="5" size="14" :value="item.value" dense readonly></v-rating>
                                                    <div>
                                                        <b>{{ item.point_id ? pointNameGet(item.point_id) : item.order_name }}</b>
                                                    </div>
                                                    <div v-if="item.review_text">
                                                        <br />
                                                        {{ item.review_text }}
                                                    </div>

                                                    <div v-for="elem in renderTextFromAudioContent(item)" :key="elem.url" class="audio-content">
                                                        <div class="audio-content__info">
                                                            <p v-show="elem.contentType" class="audio-content__info_type">{{ elem.contentType }}</p>
                                                            <p v-show="elem.duration" class="audio-content__info_duration">{{ elem.duration }}</p>
                                                        </div>
                                                        <div class="audio-content__text">
                                                            {{ elem.translatedText }}
                                                        </div>
                                                        <audio preload="auto" controls>
                                                            <source :src="elem.extraPath" />
                                                        </audio>
                                                    </div>

                                                    <div v-for="elem in renderTextFromVideoContent(item)" :key="elem.id">
                                                        <div class="audio-content__info">
                                                            <p v-show="elem.contentType" class="audio-content__info_type">{{ elem.contentType }}</p>
                                                            <p v-show="elem.duration" class="audio-content__info_duration">{{ elem.duration }}</p>
                                                        </div>
                                                        <div class="audio-content__text">
                                                            {{ elem.translatedText }}
                                                        </div>
                                                    </div>
                                                    <v-slide-group class="pa-4" center-active show-arrows style="padding: 5px !important" v-if="item.mediaFiles.isMedia">
                                                        <v-slide-item v-for="elem in item.mediaFiles.media" :key="elem.url" style="margin: 5px !important">
                                                            <v-card class="ma-4 elevation-1" height="200" width="200" style="display: flex" @click="elem.type == 'img' ? clickMedia(elem) : ''">
                                                                <video v-if="elem.type && elem.type == 'video'" :src="elem.url" width="100%" max-width="100%" height="auto" controls></video>
                                                                <v-img v-if="elem.type && elem.type == 'img'" :src="elem.url" max-width="100%" height="100%"></v-img>
                                                            </v-card>
                                                        </v-slide-item>
                                                    </v-slide-group>
                                                    <br />
                                                    <v-menu bottom color="rgba(255,255,255, 0)" open-on-hover :close-on-content-click="false" offset-y close-delay="100" open-delay="200">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span class="tooltip-text" v-bind="attrs" v-on="on"> <b>Заказ:</b> {{ item.order_name }} </span>
                                                        </template>
                                                        <v-card class="pa-2 menu_text" max-width="800px">
                                                            <div>
                                                                Название: <b>{{ item.order_name }} </b>
                                                                <v-avatar size="20">
                                                                    <img alt="source" :src="iconSource(item)" />
                                                                </v-avatar>
                                                            </div>
                                                            <div>
                                                                Точка: <b>{{ item.point_id ? pointNameGet(item.point_id) : item.order_name }}</b>
                                                            </div>
                                                            <div>
                                                                Курьер:
                                                                <b>{{ userIsPoint ? item.courier_name.split(' ')[0] : item.courier_name }}</b>
                                                            </div>
                                                            <v-divider class="mt-1 mb-1"></v-divider>
                                                            <div>
                                                                Забран: <b>{{ timeToString(item.date_take) }} </b>
                                                                <v-chip v-if="item.date_take" class="px-1 mb-1" :color="timeColorHtml(item.date_take, item.date_ready)" x-small label text-color="white">
                                                                    {{ timeDiff(item.date_take, item.date_ready) }}
                                                                </v-chip>
                                                            </div>
                                                            <div>
                                                                Доставлен: <b>{{ timeToString(item.date_complete) }}</b>
                                                                <v-chip v-if="item.date_complete" class="px-1 mb-1" :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label text-color="white">
                                                                    {{ timeDiff(item.date_complete, item.date_delivery) }}
                                                                </v-chip>
                                                            </div>
                                                        </v-card>
                                                    </v-menu>
                                                    <div>
                                                        <b>Курьер:</b>
                                                        {{ userIsPoint ? item.courier_name.split(' ')[0] : item.courier_name }}
                                                    </div>
                                                    <div>
                                                        <span class="mr-2"
                                                            ><b>Время доставки: {{ getStringToDate(item.date_complete, 'DD MMM HH:mm') }}</b></span
                                                        >
                                                        <v-chip class="px-1" :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label text-color="white">
                                                            {{ timeDiff(new Date(item.date_complete), new Date(item.date_delivery)) }}
                                                        </v-chip>
                                                    </div>
                                                </v-col>
                                                <v-col cols="3" class="d-flex flex-row-reverse">
                                                    <img v-if="item.platform || item.value < 5" height="24" alt="platform" :src="iconPlatformSource(item.platform)" />
                                                    <span v-else> Без перехода </span>

                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span class="tooltip-text" v-bind="attrs" v-on="on" @click="onClickReview(item)">
                                                                <v-btn class="pb-1" v-if="respondTooltip(item).includes('без ответа')" plain text small :style="{ color: respondColorHtml(item) }"> Ответить по SMS</v-btn>
                                                                <v-icon v-else class="mx-3" :style="{ color: respondColorHtml(item) }">{{ respondIcon(item) }}</v-icon>
                                                            </span>
                                                        </template>
                                                        <span style="white-space: pre">{{ respondTooltip(item) + textClick(respondTooltip(item)) }}</span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-btn icon small style="margin: 0 0 0 5px" @click="clickUpdateTextMade(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                                                    <span v-if="item.text_made" style="white-space: preserve-breaks; color: DarkSlateBlue; padding: 0 10px">{{ item.text_made }}</span>
                                                    <span v-else style="color: DarkSlateBlue; padding: 0 10px"> <i>Отзыв еще не отработан</i></span>
                                                </v-col>
                                                <v-col cols="6" class="d-flex align-end">
                                                    <div>
                                                        <v-chip-group column>
                                                            <v-chip v-for="tag in item.hashtags" :key="tag" outlined @click="clickHashtag(item, tag)" small>
                                                                {{ tag }}
                                                            </v-chip>
                                                            <v-chip outlined @click="clickAddHashtag(item)" small>
                                                                <v-icon color="green">mdi-plus</v-icon>
                                                            </v-chip>
                                                        </v-chip-group>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="10" style="text-align: center; color: rgba(0, 0, 0, 0.6); font-family: 'Roboto', sans-serif" v-if="!loading && countReviews >= reviews.length && reviews.length">
                                    <span>Больше отзывов нет</span>
                                </v-col>
                                <v-col cols="10" style="text-align: center" v-if="countReviews < reviews.length">
                                    <v-progress-circular indeterminate color="indigo darken-6"></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-card>
                    </div>

                    <div v-if="selectTab == 'parsed'">

                        <v-row class="py-6 px-14 justify-center">

                            <v-col cols="4">
                            </v-col>
                            
                            <v-col cols="4">
                                <v-select
                                    v-if="userIsOperator"
                                    class="flex-grow-1 mr-6 mt-0"
                                    style="width: 100%"
                                    no-data-text="Нет данных"
                                    @change="selectPointReviewParsed"
                                    name="point"
                                    item-text="name"
                                    item-value="idString"
                                    :items="points"
                                    v-model="selectParsePointId"
                                    label="Выбор точки"
                                    hide-details
                                ></v-select>
                            </v-col>


                            <v-col cols="4">
                            </v-col>
                        </v-row>
                        
                        <v-expansion-panels class="px-14 py-2" v-if="userIsOperator">
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Настройка парсера
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>

                                    <v-row class="align-center ma-0 mt-9 mt-md-3 mx-3">
                                        <v-col cols="4" md="2" class="d-flex align-center justify-space-between flex-row flex-grow-1 mb-6 mb-md-0 pa-0">
                                            <p class="mb-0 py-2">Теги для поиска</p>
                                        </v-col>
                                        <v-col cols="12" md="10" class="pa-0">
                                            <v-row>
                                                <v-text-field class="mt-0 pt-0 px-10" label="Искать выражения" hide-details v-model="currentReviewParserTagsPass"></v-text-field>
                                                <v-text-field class="mt-0 pt-0 px-10" label="Пропускать выражения" hide-details v-model="currentReviewParserTagsSkip"></v-text-field>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                    <v-row class="align-center ma-0 mt-9 mt-md-3 mx-3">
                                        <v-col cols="4" md="2" class="d-flex align-center justify-space-between flex-row flex-grow-1 mb-6 mb-md-0 pa-0">
                                            <p class="mb-0 py-2">Яндекс</p>
                                            <v-checkbox v-model="currentReviewParser.isEnabledYandex" hide-details class="mt-0 pt-0"> </v-checkbox>
                                        </v-col>
                                        <v-col cols="7" md="9" class="pa-0">
                                            <v-text-field class="mt-0 pt-0" :disabled="!currentReviewParser.isEnabledYandex" placeholder="" hide-details label="URL" v-model="currentReviewParser.urlYandex" prepend-icon="mdi-wan"></v-text-field>
                                        </v-col>
                                        <v-col cols="1" md="1" class="pa-0">
                                            <v-btn plain text small color="blue" target="_blank" :href="currentReviewParser.urlYandex">Открыть</v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-row class="align-center ma-0 mt-9 mt-md-3 mx-3">
                                        <v-col cols="4" md="2" class="d-flex align-center justify-space-between flex-row flex-grow-1 mb-6 mb-md-0 pa-0">
                                            <p class="mb-0 py-2">Google</p>
                                            <v-checkbox v-model="currentReviewParser.isEnabledGoogle" hide-details class="mt-0 pt-0"> </v-checkbox>
                                        </v-col>
                                        <v-col cols="7" md="9" class="pa-0">
                                            <v-text-field class="mt-0 pt-0" :disabled="!currentReviewParser.isEnabledGoogle" placeholder="" hide-details label="URL" v-model="currentReviewParser.urlGoogle" prepend-icon="mdi-wan"></v-text-field>
                                        </v-col>
                                        <v-col cols="1" md="1" class="pa-0">
                                            <v-btn plain text small color="blue" target="_blank" :href="currentReviewParser.urlGoogle">Открыть</v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-row class="align-center ma-0 mt-9 mt-md-3 mx-3">
                                        <v-col cols="4" md="2" class="d-flex align-center justify-space-between flex-row flex-grow-1 mb-6 mb-md-0 pa-0">
                                            <p class="mb-0 py-2">Tripadvisor</p>
                                            <v-checkbox v-model="currentReviewParser.isEnabledTrip" hide-details class="mt-0 pt-0"> </v-checkbox>
                                        </v-col>
                                        <v-col cols="7" md="9" class="pa-0">
                                            <v-text-field class="mt-0 pt-0" :disabled="!currentReviewParser.isEnabledTrip" placeholder="" hide-details label="URL" v-model="currentReviewParser.urlTrip" prepend-icon="mdi-wan"></v-text-field>
                                        </v-col>
                                        <v-col cols="1" md="1" class="pa-0">
                                            <v-btn plain text small color="blue" target="_blank" :href="currentReviewParser.urlTrip">Открыть</v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-row class="align-center ma-0 mt-9 mt-md-3 mx-3">
                                        <v-col cols="4" md="2" class="d-flex align-center justify-space-between flex-row flex-grow-1 mb-6 mb-md-0 pa-0">
                                            <p class="mb-0 py-2">VL.RU</p>
                                            <v-checkbox v-model="currentReviewParser.isEnabledVlru" hide-details class="mt-0 pt-0"> </v-checkbox>
                                        </v-col>
                                        <v-col cols="7" md="9" class="pa-0">
                                            <v-text-field class="mt-0 pt-0" :disabled="!currentReviewParser.isEnabledVlru" placeholder="" hide-details label="URL" v-model="currentReviewParser.urlVlru" prepend-icon="mdi-wan"></v-text-field>
                                        </v-col>
                                        <v-col cols="1" md="1" class="pa-0">
                                            <v-btn plain text small color="blue" target="_blank" :href="currentReviewParser.urlVlru">Открыть</v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-row class="align-center ma-0 mt-9 mt-md-3 mx-3">
                                        <v-col cols="4" md="2" class="d-flex align-center justify-space-between flex-row flex-grow-1 mb-6 mb-md-0 pa-0">
                                            <p class="mb-0 py-2">2GIS</p>
                                            <v-checkbox v-model="currentReviewParser.isEnabledDgis" hide-details class="mt-0 pt-0"> </v-checkbox>
                                        </v-col>
                                        <v-col cols="7" md="9" class="pa-0">
                                            <v-text-field class="mt-0 pt-0" :disabled="!currentReviewParser.isEnabledDgis" placeholder="" hide-details label="URL" v-model="currentReviewParser.urlDgis" prepend-icon="mdi-wan"></v-text-field>
                                        </v-col>
                                        <v-col cols="1" md="1" class="pa-0">
                                            <v-btn plain text small color="blue" target="_blank" :href="currentReviewParser.urlDgis">Открыть</v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" class="d-flex align-center justify-center py-4">
                                            <v-btn class="mx-4" :color="currentReviewParser.isEnabled ? 'red' : 'indigo'" plain text :dark="!loading" :disabled="loading" @click="changeParsingMode">
                                                <v-icon dense class="mr-1">{{ currentReviewParser.isEnabled ? 'mdi-power-off' : 'mdi-power' }}</v-icon>
                                                {{ currentReviewParser.isEnabled ? 'Остановить' : 'Запустить' }}
                                            </v-btn>

                                            <v-btn class="mx-4" color="indigo darken-6" :dark="!loading" :loading="loading" :disabled="loading" @click="reviewParserSave">
                                                <v-icon dense class="mr-1">mdi-content-save-outline</v-icon>
                                                Сохранить
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                            
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <v-row class="my-2 mx-6 justify-center">
                            <v-card class="ma-2" width="300" height="350">
                                <v-toolbar class="mb-2" height="50" color="cyan darken-1" dark flat>
                                    <v-toolbar-title class="overline">Средняя оценка Яндекс</v-toolbar-title>
                                </v-toolbar>
                                <div class="pa-5">
                                    <div class="pie-wrapper">
                                        <Pie :chartData="chartPieYandex" :options="pie_options" class="piechartZ card-pie" />
                                        <div class="center-wrapper">
                                            <div class="d-flex justify-center align-items-center" v-if="avgRatingYandex">
                                                <span class="rating-text mr-1">{{ avgRatingYandex }}</span>
                                                <v-icon x-large color="amber">mdi-star-circle</v-icon>
                                            </div>
                                            <div class="no-data" v-else>Нет данных</div>
                                        </div>
                                    </div>
                                </div>
                            </v-card>

                            <v-card class="ma-2" width="300" height="350">
                                <v-toolbar class="mb-2" height="50" color="cyan darken-1" dark flat>
                                    <v-toolbar-title class="overline">Средняя оценка Google</v-toolbar-title>
                                </v-toolbar>
                                <div class="pa-5">
                                    <div class="pie-wrapper">
                                        <Pie :chartData="chartPieGoogle" :options="pie_options" class="piechartZ card-pie" />
                                        <div class="center-wrapper">
                                            <div class="d-flex justify-center align-items-center" v-if="avgRatingGoogle">
                                                <span class="rating-text mr-1">{{ avgRatingGoogle }}</span>
                                                <v-icon x-large color="amber">mdi-star-circle</v-icon>
                                            </div>
                                            <div class="no-data" v-else>Нет данных</div>
                                        </div>
                                    </div>
                                </div>
                            </v-card>

                            <v-card class="ma-2" width="300" height="350">
                                <v-toolbar class="mb-2" height="50" color="cyan darken-1" dark flat>
                                    <v-toolbar-title class="overline">Средняя оценка VL.RU</v-toolbar-title>
                                </v-toolbar>
                                <div class="pa-5">
                                    <div class="pie-wrapper">
                                        <Pie :chartData="chartPieVlRu" :options="pie_options" class="piechartZ card-pie" />
                                        <div class="center-wrapper">
                                            <div class="d-flex justify-center align-items-center" v-if="avgRatingVlRu">
                                                <span class="rating-text mr-1">{{ avgRatingVlRu }}</span>
                                                <v-icon x-large color="amber">mdi-star-circle</v-icon>
                                            </div>
                                            <div class="no-data" v-else>Нет данных</div>
                                        </div>
                                    </div>
                                </div>
                            </v-card>

                            <v-card class="ma-2" width="300" height="350">
                                <v-toolbar class="mb-2" height="50" color="cyan darken-1" dark flat>
                                    <v-toolbar-title class="overline">Средняя оценка 2GIS</v-toolbar-title>
                                </v-toolbar>
                                <div class="pa-5">
                                    <div class="pie-wrapper">
                                        <Pie :chartData="chartPie2gis" :options="pie_options" class="piechartZ card-pie" />
                                        <div class="center-wrapper">
                                            <div class="d-flex justify-center align-items-center" v-if="avgRating2gis">
                                                <span class="rating-text mr-1">{{ avgRating2gis }}</span>
                                                <v-icon x-large color="amber">mdi-star-circle</v-icon>
                                            </div>
                                            <div class="no-data" v-else>Нет данных</div>
                                        </div>
                                    </div>
                                </div>
                            </v-card>

                            <v-card class="ma-2" width="300" height="350">
                                <v-toolbar class="mb-2" height="50" color="cyan darken-1" dark flat>
                                    <v-toolbar-title class="overline">Средняя оценка TRIPADVISOR</v-toolbar-title>
                                </v-toolbar>
                                <div class="pa-5">
                                    <div class="pie-wrapper">
                                        <Pie :chartData="chartPieTripadvisor" :options="pie_options" class="piechartZ card-pie" />
                                        <div class="center-wrapper">
                                            <div class="d-flex justify-center align-items-center" v-if="avgRatingTripadvisor">
                                                <span class="rating-text mr-1">{{ avgRatingTripadvisor }}</span>
                                                <v-icon x-large color="amber">mdi-star-circle</v-icon>
                                            </div>
                                            <div class="no-data" v-else>Нет данных</div>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-row>

                        <div class="d-flex flex-column">
                            <v-row class="mx-1">
                                <v-col>
                                    <v-select class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" :items="quick_filters_parsed" v-model="quick_filter_parsed" label="Быстрые фильтры" @change="filtersParsed()" hide-details></v-select>
                                </v-col>
                                <v-col>
                                    <v-select class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" label="По отзовику" :items="platform" v-model="platformSelected" @change="choosePlatform" hide-details></v-select>
                                </v-col>

                                <v-col>
                                    <v-text-field class="ml-2" style="width: 340px; flex: initial" v-model="searchReviewsParsed" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details @input="filtersParsed()"> </v-text-field>
                                </v-col>

                                <v-col>
                                    <v-switch v-model="tabularViewParsed" label="Табличный вид" @change="filtersParsed()"></v-switch>
                                </v-col>
                            </v-row>

                            <v-row v-if="hashtagsParsed.length" class="mx-6 my-2">
                                <span class="mt-2 mr-3">По тегам:</span>
                                
                                <v-chip-group multiple column>
                                    <v-chip
                                        v-for="tag in hashtagsParsed"
                                        outlined
                                        small
                                        :key="tag.name"
                                        :class="tag.isSelected ? 'indigo indigo--text' : ''"
                                        @click="filtersParsed(tag)"
                                    >
                                        {{ tag.name }}
                                    </v-chip>
                                </v-chip-group>
                            </v-row>

                            <v-card v-if="tabularViewParsed" elevation="0">
                                <v-data-table
                                    dense
                                    :headers="headersReviewsParsed"
                                    :items="filterReviewsParsed"
                                    :search="searchReviewsParsed"
                                    no-data-text="Нет данных"
                                    :footer-props="{
                                        pageText: '{0} - {1} из {2}',
                                        itemsPerPageText: 'Показывать на странице',
                                        itemsPerPageAllText: 'все',
                                        showFirstLastPage: true,
                                        itemsPerPageOptions: [25, 50, 100, -1],
                                    }"
                                    :sort-by="['date']"
                                    :sort-desc="true"
                                    item-key="id"
                                    @click:row="onClickReviewParsed"
                                    :loading="loading"
                                    loading-text="Идет загрузка"
                                >
                                    <template v-slot:[`item.name`]="{ item }">
                                        <b>{{ item.name }}</b>
                                    </template>

                                    <template v-slot:[`item.userName`]="{ item }">
                                        <span style="min-width: 180px">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <td v-bind="attrs" v-on="on">
                                                        <span class="py-2 tooltip-text">{{ item.userName }}</span>
                                                    </td>
                                                </template>
                                                <span>{{ item.userName }}</span>
                                            </v-tooltip>
                                        </span>
                                    </template>

                                    <template v-slot:[`item.rating`]="{ item }">
                                        <v-rating v-if="item.rating != 0 && item.rating != null" background-color="grey lighten-1" color="warning" empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full" length="5" size="14" :value="item.rating" dense readonly></v-rating>
                                        <span v-else class="grey--text lighten-1">Без оценки</span>
                                    </template>

                                    <template v-slot:[`item.type`]="{ item }">
                                        <img class="mt-1" height="24" alt="platform" :src="iconPlatformSource2(item.type)" />
                                    </template>

                                    <template v-slot:[`item.textReview`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <!-- <div v-if="item.img" v-bind="attrs" v-on="on" class="divImgReviewParsed">
                                                    <div class="imgReviewParsed" v-for="(item,index) in item.img" :key="index">
                                                        <img :src="item" />
                                                    </div>
                                                </div> -->
                                                <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                    {{ (item.img ? '/n' : '') + item.textReview && item.textReview.length > 24 ? item.textReview.substr(0, 24) + '...' : item.textReview }}
                                                </span>
                                            </template>
                                            <!-- <div v-if="item.img" class="divImgReviewParsedOpen">
                                                <div class="imgReviewParsedOpen" v-for="(item,index) in item.img" :key="index">
                                                    <img :src="item" />
                                                </div>
                                            </div> -->
                                            <span style="white-space: pre-wrap">{{ item.textReview }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template v-slot:[`item.reviewedAt`]="{ item }">
                                        {{ timeToString(item.reviewedAt, true) }}
                                    </template>

                                    <template v-slot:[`item.textRespond`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span class="tooltip-text" style="white-space: pre-wrap" v-bind="attrs" v-on="on">
                                                    {{ item.textRespond && item.textRespond.length > 24 ? item.textRespond.substr(0, 24) + '...' : item.textRespond }}
                                                </span>
                                            </template>
                                            <span style="white-space: pre-wrap">{{ item.textRespond }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-card>

                            <v-card v-else elevation="0">
                                <v-row class="ma-0" justify="center" height="auto" max-height="600px" min-height="60px" width="100%">
                                    <v-col cols="2" align-self="center" v-if="filterReviewsParsed.length == 0 && !loading">
                                        <div>Нет данных</div>
                                    </v-col>
                                    <v-col v-for="item in 6" :key="item" v-show="loading" cols="10">
                                        <v-card elevation="2">
                                            <v-skeleton-loader :loading="loading" type="list-item-avatar-three-line"> </v-skeleton-loader>
                                        </v-card>
                                    </v-col>
                                    <v-col v-for="item in parsedReviewsBeforeScroll" v-show="!loading" cols="10" :key="item.id" align-self="center">
                                        <v-card elevation="2">
                                            <v-card-text>
                                                <v-row dense>
                                                    <v-col cols="2">
                                                        <v-menu bottom color="rgba(255,255,255, 0)" open-on-hover :close-on-content-click="false" offset-y close-delay="100" open-delay="200">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <div v-bind="attrs" v-on="on">
                                                                    <v-icon size="50px">mdi-account-circle</v-icon>
                                                                    <div>
                                                                        <b v-if="item.userName !== null">{{ item.userName }}</b> <b v-else>Имя не указано</b>
                                                                    </div>

                                                                    <div>{{ timeToString(item.reviewedAt, true) }}</div>
                                                                </div>
                                                            </template>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="8" align-self="start">
                                                        <v-rating background-color="grey lighten-1" color="warning" empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full" length="5" size="14" :value="item.rating" dense readonly></v-rating>

                                                        <div class="my-2 pa-0" style="white-space: preserve-breaks;">
                                                            {{ item.textReview }}
                                                            <p></p>
                                                            <div v-if="item.textRespond">
                                                                <b>Ответ компании:</b> <span style="white-space: preserve-breaks; color: DarkSlateBlue;">{{ item.textRespond }}</span>
                                                            </div>
                                                            <span v-else>
                                                                <i><b>Ответ на отзыв еще не получен</b></i>
                                                            </span>
                                                        </div>
                                                    </v-col>

                                                    <v-col class="d-flex flex-row-reverse" cols="2">
                                                        <img class="mt-1" height="24" alt="platform" :src="iconPlatformSource2(item.type)" />
                                                        <v-btn plain text small color="red" class="mt-1" @click="clickDeleteReviewParsed(item)">
                                                            Удалить
                                                        </v-btn>
                                                    </v-col>

                                                    <v-col cols="12">
                                                    </v-col>

                                                    <v-col cols="12">
                                                        <v-btn icon small style="margin: 0 0 0 5px" @click="clickUpdateTextMade(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                                                        <span v-if="item.textMade" style="white-space: preserve-breaks; color: DarkSlateBlue; padding: 0 10px">
                                                            <b>Комментарий:</b> {{ item.textMade }}
                                                        </span>
                                                        <span v-else style="color: DarkSlateBlue; padding: 0 10px"> <i>Оставьте комментарий к отзыву</i></span>
                                                    </v-col>

                                                    <v-col cols="10">
                                                        <div>
                                                            <v-chip-group column>
                                                                <v-chip v-for="tag in item.hashtags" :key="tag" outlined @click="clickHashtag(item, tag)" small>
                                                                    {{ tag }}
                                                                </v-chip>
                                                                <v-chip outlined @click="clickAddHashtag(item)" small>
                                                                    <v-icon color="green">mdi-plus</v-icon>
                                                                </v-chip>
                                                            </v-chip-group>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="10" style="text-align: center; color: rgba(0, 0, 0, 0.6); font-family: 'Roboto', sans-serif" v-if="countReviewsParsed >= filterReviewsParsed.length && filterReviewsParsed.length">
                                        <span>Больше отзывов нет</span>
                                    </v-col>
                                    <v-col cols="10" style="text-align: center" v-if="countReviewsParsed < filterReviewsParsed.length">
                                        <v-progress-circular indeterminate color="indigo darken-6"></v-progress-circular>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </div>
                    </div>

                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogInfoChange" :width="dialogTextMade ? '400' : '350'" @click:outside="closeDialogHashtag">
            <v-card style="max-height: 300px; overflow: scroll;">
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn icon plain small @click="closeDialogHashtag"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>

                <v-card-text>
                    <v-textarea id="textareaDialog" v-model="nameInfoText" :label="labelInfoText" auto-grow outlined rows="3" :row-height="dialogTextMade ? '20' : '10'" shaped @click="alertHashtag = false"></v-textarea>
                </v-card-text>
                <v-alert v-model="alertHashtag" dismissible dense style="position: relative; margin: 6px" :type="typeAlert" class="alert elevation-3"> Заполните поле </v-alert>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="success" dark small text @click="updateNameInfoText">Сохранить</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" v-if="!dialogTextMade" dark small text @click="clickDelete">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogMedia" :width="currentMedia ? currentMedia.width : '720'" :height="currentMedia ? currentMedia.height : '570'" @click:outside="dialogMedia = false">
            <v-card width="auto" height="auto">
                <v-layout align-end>
                    <v-menu absolute offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon plain small v-bind="attrs" v-on="on"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                        </template>
                        <v-list dense style="padding: 0" rounded outlined>
                            <v-list-item @click="downloadMedia(currentMedia.url, currentMedia.path)">
                                <v-list-item-title>Скачать</v-list-item-title>
                                <v-icon>mdi-content-save-outline</v-icon>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <v-btn icon plain small style="margin-right: 5px" @click="dialogMedia = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-layout>
                <video v-if="currentMedia && currentMedia.type == 'video'" :src="currentMedia.url" controls></video>
                <v-img v-if="currentMedia && currentMedia.type == 'img'" :src="currentMedia.url"></v-img>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogDelete"
            width="250"
            @click:outside="
                dialogDelete = false
                dialogInfoChange = true
            "
        >
            <v-card>
                <v-card-text class="text-center" style="display: inline-block; margin: 25px 0 0 0">Вы уверены что хотите удалить?</v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                    <v-btn width="50%" color="success" dark small text @click="deleteHashtags">Да</v-btn>
                    <v-btn
                        width="50%"
                        color="error"
                        dark
                        small
                        text
                        @click="
                            dialogDelete = false
                            dialogInfoChange = true
                        "
                        >Нет</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogReview" width="720" @click:outside="handleCloseReviewDialog">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{ `Отзыв гостя ${currentItem.guest_name ? currentItem.guest_name : currentItem.guest_phone}` }}
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="10">
                                    <div class="d-inline-flex">
                                        <span class="mr-5 my-1 align-center">Оценка</span>
                                        <v-rating background-color="grey lighten-1" color="warning" empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full" length="5" size="24" :value="selectedItem.value" readonly dense></v-rating>
                                    </div>
                                </v-col>
                                <v-col cols="10" v-if="!selectedItem.review_text">
                                    <span class="mr-5 my-1 align-center">Текст отзыва гость не написал</span>
                                </v-col>
                                <v-col cols="10" v-if="selectedItem.review_text">
                                    <v-textarea
                                        v-model="selectedItem.review_text"
                                        readonly
                                        label="Текст отзыва"
                                        auto-grow
                                        outlined
                                        rows="3"
                                        row-height="25"
                                        shaped
                                        :messages="selectedItem.status >= config.review.status.voted ? 'Время отзыва: ' + timeToString(selectedItem.date_vote) : ''"
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="10">
                                    <v-textarea
                                        ref="reviewRespond"
                                        :value="respondText"
                                        @change="v => (respondText = v)"
                                        @input="handleThrottleResponse"
                                        :label="selectedItem.status == config.review.status.responded ? 'Ответ на отзыв' : 'Отправить ответ гостю'"
                                        auto-grow
                                        outlined
                                        rows="3"
                                        row-height="25"
                                        shaped
                                        :readonly="selectedItem.status == config.review.status.responded"
                                        :messages="selectedItem.status == config.review.status.responded ? 'Время ответа: ' + timeToString(selectedItem.date_respond) : ''"
                                    ></v-textarea>
                                    <v-btn v-if="selectedItem.status != config.review.status.responded" color="success" dark @click="onSendRespond">Отправить СМС</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-alert style="position: relative; margin-bottom: 10px" :type="typeAlert" class="alert elevation-3" v-model="alert" :dismissible="true">{{ this.alertText }}</v-alert>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="primary" text @click="handleCloseReviewDialog">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogParseReview" width="720" @click:outside="dialogParseReview = false">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{ `Отзыв гостя ${selectedItemParsed.userName ? selectedItemParsed.userName : ''}` }}
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="2">
                                <v-menu bottom color="rgba(255,255,255, 0)" open-on-hover :close-on-content-click="false" offset-y close-delay="100" open-delay="200">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">
                                            <v-icon size="50px">mdi-account-circle</v-icon>
                                            <div>
                                                <b v-if="selectedItemParsed.userName !== null">{{ selectedItemParsed.userName }}</b> <b v-else>Имя не указано</b>
                                            </div>

                                            <div>{{ timeToString(selectedItemParsed.reviewedAt, true) }}</div>
                                        </div>
                                    </template>
                                </v-menu>
                            </v-col>
                            <v-col cols="8" align-self="start">
                                <span><b>Оценка</b></span>
                                <v-rating background-color="grey lighten-1" color="warning" empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full" length="5" size="16" :value="selectedItemParsed.rating" readonly dense></v-rating>

                                <v-col class="my-2 py-0">
                                </v-col>

                                <v-card elevation="0" style="overflow: scroll; max-height: 300px;">
                                    <v-card-text class="my-1 pa-0" style="white-space: preserve-breaks;">
                                        {{ selectedItemParsed.textReview }}
                                        <p></p>
                                        <div v-if="selectedItemParsed.textRespond">
                                            <b>Ответ компании:</b> <span style="white-space: preserve-breaks; color: DarkSlateBlue;">{{ selectedItemParsed.textRespond }}</span>
                                        </div>
                                        <span v-else>
                                            <i><b>Ответ на отзыв еще не получен</b></i>
                                        </span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col class="d-flex justify-end" cols="2">
                                <img class="mt-1" height="24" alt="platform" :src="iconPlatformSource2(selectedItemParsed.type)" />
                            </v-col>

                            <v-col cols="12">
                                <v-btn icon small style="margin: 0 0 0 5px" @click="clickUpdateTextMade(selectedItemParsed)"><v-icon>mdi-pencil</v-icon></v-btn>
                                <span v-if="selectedItemParsed.textMade" style="white-space: preserve-breaks; color: DarkSlateBlue; padding: 0 10px"><b>Комментарий:</b> {{ selectedItemParsed.textMade }}</span>
                                <span v-else style="color: DarkSlateBlue; padding: 0 10px"> <i>Оставьте комментарий к отзыву</i></span>
                            </v-col>
                            <v-col cols="12">
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn plain text color="red" @click="clickDeleteReviewParsed(selectedItemParsed)">Удалить</v-btn>
                    <v-btn class="mx-2" color="primary" text @click="dialogParseReview = false">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptDelete" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">
                    Удаление отзыва
                    <v-spacer></v-spacer>
                </v-card-title>

                <v-card-text class="pt-4">
                    <span>Вы действительно хотите удалить отзыв?</span>
                </v-card-text>
                <v-card-text class="py-2">
                    <i>Если отзыв существует в источнике, то он будет создан снова.</i>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="error" text @click="promptDelete = false">Нет</v-btn>
                    <v-btn class="mx-2" color="success" text @click="promptDeleteYes">Да</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <span class="pb-16 d-block" />
    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'
    moment.locale('ru')

    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Notify from '../../mixins/Notify.js'

    import Pie from '../analytics/Pie'
    import { throttle } from '../../helpers'

    export default {
        mixins: [DateTime, Status, Notify],
        data() {
            return {
                dates: [],
                datesParsed: [],
                selectTab: 'inner',
                calendar: 0,
                calendar_parsed: 0,
                menu_calendar: false,
                menu_calendar_parsed: false,
                loading: false,
                search: '',
                loyalties_count: 0,
                reviewsStars: 0,
                reviews: [],
                points: [],
                couriers: [],
                filter_point: 0,
                filter_points: [],
                filter_courier: 0,
                filter_couriers: [],
                headers: [],
                orderStatuses: [],
                timer: null,

                promptDelete: false,
                dialogParseReview: false,
                dialogReview: false,
                dialogHeader: '',
                dialogContent: '',
                respondText: '',
                config,
                currentItem: {},
                selectedItem: {},
                selectedItemParsed: {},
                timerAddressUpdate: null,
                addressUpdateFlag: false,
                changeFlag: false,
                alert: false,
                alertText: '',
                typeAlert: 'warning',
                status_codes: ['103', '104', '105', '106', '107', '108', '150', '200', '201', '202', '203', '204', '205', '206', '207', '208', '209', '213', '220', '230', '231', '232', '233', '300', '301', '302', '500'],
                d0: null,
                d1: null,
                d2: null,
                dp0: null,
                dp1: new Date().toISOString().substring(0, 10),
                dp2: new Date().toISOString().substring(0, 10),
                userIsOperator: false,
                userIsPoint: false,
                guestNameClick: false,
                guestCreateClick: false,

                tabularView: false,
                tabularViewParsed: false,
                reviewsAll: [],
                hashtags: [],
                hashtagsParsed: [],
                quick_filters: [],
                quick_filter: '',
                dialogInfoChange: false,
                dialogDelete: false,
                indexHashtag: 0,
                nameInfoText: '',
                alertHashtag: false,
                dialogTextMade: false,
                labelInfoText: '',

                dialogMedia: false,
                currentMedia: null,

                countReviews: 6,
                countReviewsParsed: 6,
                reviewsParsed: [],
                filterReviewsParsed: [],
                reviewsParsedByTime: [],
                headersReviewsParsed: [],
                headersSelectionTables: [],
                dialogSelectionTables: false,
                searchReviewsParsedDialog: '',
                selectedReviewParsed: null,
                searchReviewsParsed: '',
                platform: ['< Все >', 'Vlru', 'Yandex', 'Google', 'Trip', 'Dgis'],
                platformSelected: '< Все >',

                selectParsePoint: null,
                selectParsePointId: null,
                pointUsers: [],

                pie_options: {
                    cutoutPercentage: 70,
                    segmentShowStroke: true,
                    segmentStrokeColor: '#fff',
                    segmentStrokeWidth: 2,
                    // percentageInnerCutout: 50,
                    animationSteps: 100,
                    animationEasing: 'easeOutBounce',
                    animateRotate: true,
                    animateScale: false,
                    responsive: true,
                    maintainAspectRatio: false,
                    showScale: true,

                    legend: {
                        display: false,
                    },
                    tooltips: {
                        callbacks: {},
                        backgroundColor: '#FFF',
                        titleFontSize: 16,
                        titleFontColor: '#3f51b5',
                        bodyFontColor: '#666666',
                        //bodyFontSize: 14,
                        displayColors: false,
                        borderColor: '#3f51b5',
                        borderWidth: 2,
                        bodySpacing: 5,
                    },
                },

                chartPieYandex: {},
                chartPieGoogle: {},
                chartPieTripadvisor: {},
                chartPieVlRu: {},
                chartPie2gis: {},
                avgRatingYandex: 0,
                avgRatingGoogle: 0,
                avgRatingTripadvisor: 0,
                avgRatingVlRu: 0,
                avgRating2gis: 0,
                currentReviewParser: null,
                currentReviewParserTagsPass: [],
                currentReviewParserTagsSkip: [],
                reviewParsedId: null,
            }
        },
        components: {
            Pie,
        },
        created() {
            this.handleThrottleResponse = throttle(this.handleChangeResponse.bind(this), 250)
            this.currentReviewParserReset()
        },
        mounted() {
            this.userIsPoint = this.$store.state.user.role == config.user.role.point
            this.userIsOperator = this.$store.state.user.role == config.user.role.operator
            if (this.userIsPoint) {
                this.headers = [
                    {
                        text: 'Гость',
                        value: 'guest',
                    },
                    {
                        text: 'Оценка',
                        value: 'value',
                    },
                    {
                        text: 'Отзовик',
                        value: 'platform',
                    },
                    {
                        text: 'Ответ',
                        value: 'respond',
                    },
                    {
                        text: 'Текст отзыва',
                        sortable: false,
                        value: 'review_text',
                    },
                    {
                        text: 'Время отзыва',
                        sortable: false,
                        value: 'date_vote',
                    },
                    // {
                    //     text: 'Точка',
                    //     value: 'point_name',
                    // },
                    {
                        text: 'Курьер',
                        value: 'courier_name',
                    },
                    {
                        text: 'Заказ',
                        sortable: false,
                        value: 'order_name',
                    },
                    {
                        text: 'Заказ доставлен',
                        sortable: false,
                        value: 'date_complete',
                    },
                ]
            } else {
                this.headers = [
                    {
                        text: 'Гость',
                        value: 'guest',
                    },
                    {
                        text: 'Оценка',
                        value: 'value',
                    },
                    {
                        text: 'Отзовик',
                        value: 'platform',
                    },
                    {
                        text: 'Ответ',
                        value: 'respond',
                    },
                    {
                        text: 'Текст отзыва',
                        sortable: false,
                        value: 'review_text',
                    },
                    {
                        text: 'Время отзыва',
                        sortable: false,
                        value: 'date_vote',
                    },
                    {
                        text: 'Точка',
                        value: 'point_name',
                    },
                    {
                        text: 'Курьер',
                        value: 'courier_name',
                    },
                    {
                        text: 'Заказ',
                        sortable: false,
                        value: 'order_name',
                    },
                    {
                        text: 'Заказ доставлен',
                        sortable: false,
                        value: 'date_complete',
                    },
                ]
            }
            this.dates[0] = moment().format('YYYY-MM-DD')
            this.dates[1] = moment().format('YYYY-MM-DD')
            this.d0 = this.getDate1String(-4)
            this.d1 = this.getDate1String()
            this.d2 = this.getDate2String()
            this.quick_filters = ['<Все>', 'Положительные отзывы', 'Негативные отзывы', 'Отзывы с текстом', 'Отзывы без текста', 'Отзывы с медиа', 'Не отработанные отзывы', 'Отработанные отзывы']
            this.quick_filter = this.quick_filters[0]
            this.quick_filters_parsed = ['<Все>', 'Положительные отзывы', 'Негативные отзывы', 'Отзывы с медиа', 'Не отработанные отзывы', 'Отработанные отзывы']
            this.quick_filter_parsed = this.quick_filters_parsed[0]

            this.headersReviewsParsed = [
                { text: 'Отзовик', align: 'start', value: 'type' },
                { text: 'Время отзыва', value: 'reviewedAt', sortable: false },
                { text: 'Гость', value: 'userName' },
                { text: 'Оценка', value: 'rating' },
                { text: 'Текст отзыва', value: 'textReview', sortable: false },
                { text: 'Ответ компании', value: 'textRespond', sortable: false },
            ]
            this.updateData()
            this.getNextReview()
            this.getNextReviewParsed()
        },
        destroyed() {
            return this.timer ? clearTimeout(this.timer) : false
        },
        computed: {
            dateRangeText() {
                return this.dates.join(' ~ ')
            },
            reviewsBeforeScroll() {
                return this.reviews.slice(0, this.countReviews - 1)
            },
            parsedReviewsBeforeScroll() {
                return this.filterReviewsParsed.slice(0, this.countReviewsParsed - 1)
            },
        },
        methods: {
            clickDeleteReviewParsed(reviewParsed) {
                this.promptDelete = true
                this.reviewParsedId = reviewParsed.id
            },
            promptDeleteYes() {
                this.promptDelete = false
                this.loading = true

                this.$store
                    .dispatch('review_parsed_delete', this.reviewParsedId)
                    .then(res => {
                        if (!res.data.success) {
                            this.notifyToast(`Ошибка удаления. Попробуйте снова. (${res.data.msg})`, { color: 'error', timeout: 10000 })
                        } else {
                            this.notifyToast('Отзыв удален успешно.', { color: 'success', timeout: 4000 })
                        }
                        this.dialogParseReview = false
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                        this.notifyToast('Ошибка удаления. Проверьте настройки сети и попробуйте снова.', { color: 'error', timeout: 10000 })
                        console.error(err)
                    })

                this.updateData()
            },
            handleCloseReviewDialog() {
                this.dialogReview = false
                this.alert = false

                if (this.timer) clearTimeout(this.timer)
            },
            handleChangeResponse(value) {
                this.respondText = this.$refs.reviewRespond.$refs.input.value
            },
            renderTextFromVideoContent(item) {
                const mediaArray = item.media
                const result = mediaArray.filter(elem => {
                    return elem.type_media === 'video' && !!elem.path
                })
                result.forEach(e => {
                    const duration = Number(e.fileDuration.substring(0, e.fileDuration.length - 2))
                    if (duration >= 1 && duration < 10) {
                        e.duration = `00:0${duration}`
                        e.contentType = 'Видео'
                    } else if (duration >= 10 && duration < 60) {
                        e.duration = `00:${duration}`
                        e.contentType = 'Видео'
                    } else if (duration >= 60 && duration < 120) {
                        e.duration = `01:${duration - 60}`
                        e.contentType = 'Видео'
                    } else {
                        e.duration = ''
                        e.contentType = ''
                    }
                })
                return result
            },
            renderTextFromAudioContent(item) {
                const mediaArray = item.media
                const result = mediaArray.filter(elem => {
                    return elem.type_media === 'audio' && !!elem.path
                })
                result.forEach(e => {
                    e.extraPath = `${config.api.delivery}media_file?path=${e.path}`
                    e.contentType = 'Аудио'
                    const duration = Number(e.fileDuration.substring(0, e.fileDuration.length - 2))
                    if (duration >= 1 && duration < 10) {
                        e.duration = `00:0${duration}`
                    } else if (duration >= 10 && duration < 60) {
                        e.duration = `00:${duration}`
                    } else if (duration >= 60 && duration < 120) {
                        e.duration = `01:${duration - 60}`
                    } else {
                        e.duration = ''
                    }
                })
                return result
            },
            onClickGuest(order) {
                if (order.guest_id) {
                    this.guestNameClick = true
                    const name = order.guest_name ? order.guest_name + ' ' : ''
                    this.notifyToast('Гость: ' + name + order.guest_phone, { color: 'info', timeout: 4000 })
                    setTimeout(() => {
                        this.guestNameClick = false
                    }, 100)
                }
            },
            getGuestPhoneName(order) {
                let guest_name = ''
                if (order.guest_id && order.guest_phone) {
                    guest_name += order.guest_phone
                    if (order.guest_name) guest_name += ' - ' + order.guest_name
                } else {
                    if (order.guest) {
                        guest_name = this.getGuestStr(order.guest).substr(0, 24)
                    }
                }
                return guest_name
            },
            onSendRespond() {
                let subscriber
                const re = /\+?[78][ ]?[-(]?\d{3}[-)]?[ ]?\d{3}[ -]?\d{2}[ -]?\d{2}/
                if (!re.test(this.selectedItem.guest)) {
                    subscriber = ''
                    this.typeAlert = 'warning'
                    this.alert = true
                    this.alertText = `Не могу отправить сообщение. Некорректный номер гостя: ${this.selectedItem.guest}`
                    return
                } else {
                    subscriber = re
                        .exec(this.selectedItem.guest)[0]
                        .replace(/[\s\-()]+/g, '')
                        .trim()

                    if (subscriber.startsWith('8')) {
                        subscriber = `+7${subscriber.substring(1)}`
                    }
                }

                this.$store
                    .dispatch('sms_send', {
                        point_id: this.selectedItem.point_id,
                        phone: subscriber,
                        text: this.respondText,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.showAlert('warning', res.data.description)
                            this.smsStatusCheck(res.data.message_id)
                            this.selectedItem.respond_text = this.respondText
                            this.selectedItem.date_respond = new Date()
                            this.selectedItem.status = config.review.status.responded
                            this.currentItem.respond_text = this.selectedItem.respond_text
                            this.currentItem.date_respond = this.selectedItem.date_respond
                            this.currentItem.status = this.selectedItem.status
                            this.saveReview(this.currentItem)
                        } else {
                            this.showAlert('error', `Сообщение не отправлено. ${res.data.description || 'Введите текст сообщения'}`)
                        }
                    })
                    .catch(err => {
                        this.showAlert('error', 'Сообщение не отправлено.')
                        console.error(err)
                    })
            },
            smsStatusCheck(message_id) {
                this.timer = setTimeout(() => {
                    this.$store
                        .dispatch('sms_status', {
                            point_id: this.selectedItem.point_id,
                            message_id,
                        })
                        .then(res => {
                            if (res.data.success) {
                                if (res.data.msg != 'ok') {
                                    this.showAlert('warning', res.data.description)
                                    this.smsStatusCheck(message_id)
                                } else {
                                    this.showAlert('success', res.data.description)
                                }
                            } else {
                                console.error('sms_status fail', res.data?.msg)
                            }
                        })
                        .catch(err => {
                            console.error(err)
                        })
                }, 2000)
            },
            showAlert(type, text) {
                this.typeAlert = type
                this.alert = true
                this.alertText = text
            },
            onClickReview(item) {
                if (this.guestNameClick || this.dialogInfoChange) return
                this.dialogHeader = `Отзыв гостя ${item.guest_name ? item.guest_name : item.guest_phone}`
                this.currentItem = item
                this.selectedItem = JSON.parse(JSON.stringify(item))
                this.respondText = this.selectedItem.respond_text
                this.dialogReview = true
            },
            onClickChangeDate(arg) {
                this.d0 = this.getDate1StringFromDate(this.dates[0])
                this.d1 = this.getDate1StringFromDate(this.dates[0])
                this.d2 = this.getDate2StringFromDate(this.dates[1])
                this.updateData()
            },
            onClickDownloadData() {
                let point
                let operator
                if (this.userIsPoint) {
                    point = this.$store.state.user.point_id
                    operator = this.$store.state.user.operator_tid
                } else {
                    point = this.filter_point
                    operator = this.$store.state.user.tid
                }
                let url = `${config.api.delivery}reviews_export?tz=${this.$store.state.settings.tz}&tz_string=${this.$store.state.settings.tz_string}&operator_tid=${operator}&date1=${this.d1}&date2=${this.d2}&point=${point}&courier=${this.filter_courier}`
                window.location.href = url
            },
            onClickToday() {
                this.dates[0] = moment().format('YYYY-MM-DD')
                this.dates[1] = moment().format('YYYY-MM-DD')
                this.d0 = this.getDate1String(-14)
                this.d1 = this.getDate1String()
                this.d2 = this.getDate2String()
                this.updateData()
            },
            onClickYesterday() {
                this.dates[0] = moment().subtract(1, 'd').format('YYYY-MM-DD')
                this.dates[1] = moment().subtract(1, 'd').format('YYYY-MM-DD')
                this.d0 = this.getDate1String(-15)
                this.d1 = this.getDate1String(-1)
                this.d2 = this.getDate2String(-1)
                this.updateData()
            },
            onClickMonth() {
                this.dates[0] = moment().subtract(30, 'd').format('YYYY-MM-DD')
                this.dates[1] = moment().format('YYYY-MM-DD')
                this.d0 = this.getDate1String(-44)
                this.d1 = this.getDate1String(-30)
                this.d2 = this.getDate2String()
                this.updateData()
            },
            onClickUpdate() {
                this.updateData()
            },
            getGuestStr(guest) {
                let splittedStr = guest.split('Телефон:')
                return splittedStr.length > 1 ? splittedStr[0] + ' ' + splittedStr[1].substr(0, 15) : guest
            },
            getTdCatted(str) {
                return '<td>Hello</td>'
            },
            pointNameGet: function (point_id) {
                let point_name = '--'
                const point = this.points.find(point => point.id == point_id)
                if (point) {
                    point_name = point.name
                }
                return point_name
            },
            getRandomInt() {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            },
            updatePoints() {
                this.$store.dispatch('points_get').then(pointsRes => {
                    if (pointsRes?.data?.success) {
                        this.points = pointsRes.data.points

                        if (this.userIsPoint) {
                            this.points = this.points.filter(p => p.id == this.$store.state.user.point.id)
                        }

                        this.selectParsePointId = this.points[0].idString
                        this.selectPointReviewParsed()

                        this.filter_points = this.points.map(p => ({ id: p.id, name: p.name }))
                        this.filter_points.splice(0, 0, { id: 0, name: '< Все >' })
                    }
                })
            },
            updateData() {
                this.loading = true

                let point_id
                if (this.userIsPoint) {
                    point_id = this.$store.state.user.point_id
                } else {
                    point_id = this.filter_point
                }

                this.$store
                    .dispatch('reviews_date', {
                        date1: this.d1,
                        date2: this.d2,
                    })
                    .then(async reviewsRes => {
                        this.reviews = []

                        if (reviewsRes?.data?.success) {
                            const { reviews } = reviewsRes.data
                            this.loyalties_count = reviews.length
                            this.reviews = reviews.filter(l => l.status > 1)

                            let review_stars = 0

                            for (let review of this.reviews) {
                                review_stars += +review.value
                                review.hashtags = review.hashtags ? review.hashtags.replace('food', '#Еда').replace('delivery', '#Доставка') : review.hashtags
                                review.hashtags = JSON.parse(review.hashtags)
                                review.mediaFiles = {
                                    audio: [],
                                    media: [],
                                    isAudio: false,
                                    isMedia: false,
                                    checkMedia: false,
                                }
                            }

                            this.reviewsStars = review_stars / this.reviews.length

                            this.reviewsAll = this.reviews
                            this.filters()
                        } else {
                            this.reviewsStars = 0
                        }

                        if (this.points.length === 0) {
                            this.updatePoints()
                        } else {
                            this.selectPointReviewParsed()
                        }

                        if (this.couriers.length === 0) {
                            this.$store.dispatch('couriers_get').then(couriersRes => {
                                if (couriersRes?.data?.success) {
                                    this.couriers = couriersRes.data.couriers

                                    this.filter_couriers = this.couriers.map(c => ({ tid: c.tid, name: this.userIsPoint ? c.full_name.split(' ')[0] : c.full_name }))
                                    this.filter_couriers.splice(0, 0, { tid: 0, name: '< Все >' })
                                }
                            })
                        }

                        const guestsRes = await this.$store.dispatch('guests_get', {
                            date1: this.d0,
                            date2: this.d2,
                            point_id,
                        })

                        if (guestsRes?.data?.success) {
                            const { guests } = guestsRes.data

                            for (let review of this.reviewsAll) {
                                if (review.guest_id) {
                                    let review_c = this.reviews.find(r => r.id == review.id)
                                    const guest = guests.find(g => g.id == review.guest_id)
                                    if (guest) {
                                        review.guest_name = guest.name
                                        review.guest_phone = guest.phone

                                        if (point_id) {
                                            guest.date_create = guest.options.points[point_id].create
                                            guest.date_visit = guest.options.points[point_id].visit
                                            guest.count = guest.options.points[point_id].count
                                            guest.cost = guest.options.points[point_id].cost

                                            if (guest.cost < 99 * guest.count) {
                                                guest.cost = 500 * guest.count
                                            }

                                            guest.other_count = guest.order_count - guest.options.points[point_id].count
                                            guest.other_cost = guest.pay_cost - guest.options.points[point_id].cost
                                        }

                                        if (guest.other_cost < 99 * guest.other_count) {
                                            guest.other_cost = 500 * guest.other_count
                                        }

                                        if (!this.$store.state.userIsPoint && guest.point_ids.length) {
                                            guest.points = ''
                                            for (const point_id of guest.point_ids) {
                                                const point = this.points.find(p => p.id == point_id)
                                                if (point) {
                                                    guest.points += point.name + ', '
                                                } else {
                                                    guest.points += 'скрыто, '
                                                }
                                            }

                                            guest.points = guest.points.substring(0, guest.points.length - 2)
                                        }

                                        review.guest_item = guest
                                        if (review_c) review_c = review
                                    }
                                }
                            }
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        console.error(err)
                        this.loading = false
                    })
            },
            closeCalendar() {
                this.menu_calendar = false
            },
            closeCalendarParsed() {
                this.menu_calendar_parsed = false
            },
            filterTags() {
                var selectedHashtags = this.hashtags
                var hashtagNames = []
                this.reviewsAll.forEach(review => review.hashtags?.forEach(hashtag => hashtagNames.push(hashtag)))

                hashtagNames = new Set(hashtagNames)
                this.hashtags = []

                for (let hashtagName of hashtagNames) {
                    this.hashtags.push({
                        name: hashtagName,
                        isSelected: selectedHashtags.find(hashtagSel => hashtagName === hashtagSel.name)?.isSelected ?? false,
                    })
                }
            },
            filters(hashtag) {
                this.filterTags()

                if (hashtag != undefined) {
                    hashtag = this.hashtags.find(tag => tag.name === hashtag.name)

                    if (hashtag) {
                        hashtag.isSelected = !hashtag.isSelected
                    }
                }
                
                let reviews = this.reviewsAll

                // Фильтр по хештегу
                let count = 0
                for (let tag of this.hashtags) if (!tag.isSelected) count++
                if (count != this.hashtags.length) {
                    reviews = reviews.filter(review => this.hashtags.find(hashtag => hashtag?.isSelected && review.hashtags?.find(tag => hashtag.name === tag)))
                }

                // Фильтр по точке
                if (this.filter_point) {
                    reviews = reviews.filter(review => review.point_id === this.filter_point)
                }
                // Фильтр по курьеру
                if (this.filter_courier) {
                    reviews = reviews.filter(review => review.courier_tid === this.filter_courier)
                }

                // Быстрые фильтры
                reviews = reviews.filter(
                    review =>
                        (this.quick_filter === '<Все>' ? true : false) ||
                        (this.quick_filter === 'Положительные отзывы' ? review.value === 5 : false) ||
                        (this.quick_filter === 'Негативные отзывы' ? review.value != 5 : false) ||
                        (this.quick_filter === 'Отзывы с текстом' ? review.review_text : false) ||
                        (this.quick_filter === 'Отзывы без текста' ? !review.review_text : false) ||
                        (this.quick_filter === 'Отзывы с медиа' ? review.media.length : false) ||
                        (this.quick_filter === 'Не отработанные отзывы' ? !review.text_made : false) ||
                        (this.quick_filter === 'Отработанные отзывы' ? review.text_made : false)
                )

                // Поиск по фразе
                if (!this.tabularView) {
                    reviews = reviews.filter(
                        review =>
                            (review.courier_name ? review.courier_name.search(new RegExp(this.search, 'i')) != -1 : false) ||
                            (review.guest_name ? review.guest_name.search(new RegExp(this.search, 'i')) != -1 : false) ||
                            (review.guest_phone ? review.guest_phone.search(new RegExp(this.search, 'i')) != -1 : false) ||
                            (review.order_name ? review.order_name.search(new RegExp(this.search, 'i')) != -1 : false) ||
                            (review.review_text ? review.review_text.search(new RegExp(this.search, 'i')) != -1 : false)
                    )
                }
                
                var review_stars = 0
                for (let review of reviews) {
                    review_stars += +review.value
                }
                this.reviewsStars = review_stars / reviews.length
                this.countReviews = 6
                this.reviews = reviews
                this.getMedia(0, this.countReviews - 1)
            },
            filterTagsParsed() {
                var selectedHashtags = this.hashtagsParsed
                var hashtagNames = []
                this.reviewsParsedByTime.forEach(reviewParsed => reviewParsed.hashtags?.forEach(hashtag => hashtagNames.push(hashtag)))

                hashtagNames = new Set(hashtagNames)
                this.hashtagsParsed = []

                for (let hashtagName of hashtagNames) {
                    this.hashtagsParsed.push({
                        name: hashtagName,
                        isSelected: selectedHashtags.find(hashtagSel => hashtagName === hashtagSel.name)?.isSelected ?? false,
                    })
                }
            },
            filtersParsed(hashtag) {
                this.filterTagsParsed()

                if (hashtag != undefined) {
                    hashtag = this.hashtagsParsed.find(tag => tag.name === hashtag.name)
                    
                    if (hashtag) {
                        hashtag.isSelected = !hashtag.isSelected
                    }
                }

                let reviews = this.reviewsParsedByTime
                
                // Фильтр по хештегу
                let count = 0
                for (let tag of this.hashtagsParsed) if (!tag.isSelected) count++
                if (count != this.hashtagsParsed.length) {
                    reviews = reviews.filter(review => this.hashtagsParsed.find(hashtagParsed => hashtagParsed?.isSelected && review.hashtags.find(tag => hashtagParsed.name === tag)))
                }

                // Поиск по фразе
                if (!this.tabularViewParsed) {
                    reviews = reviews.filter(
                        review =>
                            (review.userName ? review.userName.search(new RegExp(this.searchReviewsParsed, 'i')) != -1 : false) ||
                            (review.textReview.search(new RegExp(this.searchReviewsParsed, 'i')) != -1) ||
                            (review.textRespond ? review.textRespond.search(new RegExp(this.searchReviewsParsed, 'i')) != -1 : false) ||
                            (review.textMade ? review.textMade.search(new RegExp(this.searchReviewsParsed, 'i')) != -1 : false)
                    )
                }

                // Фильтр по платформе
                if (this.platformSelected != '< Все >') {
                    reviews = reviews.filter(review => review.type == this.platformSelected)
                }

                // Быстрые фильтры
                reviews = reviews.filter(
                    review =>
                        (this.quick_filter_parsed === '<Все>' ? true : false) ||
                        (this.quick_filter_parsed === 'Положительные отзывы' ? review.rating === 5 : false) ||
                        (this.quick_filter_parsed === 'Негативные отзывы' ? review.rating != 5 : false) ||
                        (this.quick_filter_parsed === 'Отзывы с медиа' ? review.photos.length : false) ||
                        (this.quick_filter_parsed === 'Не отработанные отзывы' ? !review.textRespond : false) ||
                        (this.quick_filter_parsed === 'Отработанные отзывы' ? review.textRespond : false)
                )

                this.filterReviewsParsed = reviews
            },
            choosePlatform() {
                this.filtersParsed()
            },
            async saveReview(review) {
                review.updateInfoText = true
                return this.$store
                    .dispatch('review_set', review)
                    .then(res => {
                        if (res.data.success) {
                            console.log('review_set success')
                        } else {
                            console.error('review_set not success', res.data?.msg)
                        }
                        review.updateInfoText = false
                    })
                    .catch(err => {
                        console.error('review_set error', err)
                        review.updateInfoText = false
                    })
            },
            async saveReviewParsed(review) {
                return this.$store
                    .dispatch('review_parsed_set', review)
                    .catch(err => {
                        console.error(err)
                    })
            },
            updateNameInfoText() {
                if (this.dialogTextMade) {
                    if (this.nameInfoText === '') {
                        this.currentItem.textMade = null
                        this.currentItem.text_made = null
                    } else {
                        this.currentItem.textMade = this.nameInfoText
                        this.currentItem.text_made = this.nameInfoText
                    }

                    this.updateInfoText()
                } else {
                    if (this.nameInfoText === '') {
                        this.alertHashtag = true    
                    } else {
                        this.currentItem.hashtags[this.indexHashtag] = this.nameInfoText
                        this.updateInfoText()
                    }
                }
            },
            updateInfoText() {
                this.dialogInfoChange = false
                this.dialogTextMade = false
                this.alertHashtag = false
                this.nameInfoText = ''

                if (this.currentItem?.type != undefined) {
                    this.saveReviewParsed(this.currentItem)
                    this.filtersParsed()
                } else {
                    this.saveReview(this.currentItem)
                    this.filters()
                }
            },
            deleteHashtags() {
                this.alertHashtag = false
                this.dialogDelete = false
                this.dialogInfoChange = false

                this.currentItem.hashtags.splice(this.indexHashtag, 1)
                this.updateInfoText()
            },
            clickHashtag(item, tag) {
                this.currentItem = item
                this.indexHashtag = item.hashtags.indexOf(tag)
                this.nameInfoText = tag
                this.labelInfoText = 'Хештег ' + tag
                this.dialogInfoChange = true
                this.dialogReview = false
            },
            clickAddHashtag(item) {
                this.currentItem = item
                if (!this.currentItem.hashtags) this.currentItem.hashtags = []
                this.indexHashtag = this.currentItem.hashtags.length != 0 ? this.currentItem.hashtags.length : 0
                this.nameInfoText = ''
                this.labelInfoText = 'Новый хештег'
                this.dialogInfoChange = true
                this.dialogReview = false
            },
            closeDialogHashtag() {
                this.dialogInfoChange = false
                this.dialogTextMade = false
                this.alertHashtag = false
                this.nameInfoText = ''
            },
            clickDelete() {
                if (this.currentItem.hashtags[this.indexHashtag]) this.dialogDelete = true
                this.dialogInfoChange = false
                this.alertHashtag = false
            },
            clickUpdateTextMade(item) {
                this.nameInfoText = item.text_made || item.textMade
                this.currentItem = item

                if (this.currentItem?.type != undefined) {
                    this.labelInfoText = 'Комментарий к отзыву'
                } else {
                    this.labelInfoText = 'Как был отработан данный отзыв?'
                }

                this.dialogInfoChange = true
                this.dialogTextMade = true
            },
            getMedia(start, end) {
                for (let item of this.reviews.slice(start, end)) {
                    if (!item.mediaFiles.checkMedia && item.media.length) {
                        item.mediaFiles.checkMedia = true
                        for (let file of item.media) {
                            if (!file.path) continue

                            this.$store
                                .dispatch('get_media_file', file.path)
                                .then(res => {
                                    if (file.type_media === 'audio') {
                                        item.mediaFiles.audio.push({
                                            path: file.path,
                                            url: res.request.responseURL,
                                        })
                                        item.mediaFiles.isAudio = true
                                    } else {
                                        item.mediaFiles.media.push({
                                            path: file.path,
                                            url: res.request.responseURL,
                                            type: file.type_media,
                                        })
                                        item.mediaFiles.isMedia = true
                                    }
                                    this.countReviews++
                                    console.log('media item', res.request.responseURL)
                                    this.countReviews--
                                })
                                .catch(err => {
                                    console.error(err)
                                    let index = item.media.indexOf(file)
                                    if (index > -1) {
                                        item.media.splice(index, 1)
                                        // this.filters()
                                    }
                                })
                        }
                    }
                }
            },
            textClick(text) {
                return text.includes('без ответа') ? '\nНажмите чтобы ответить' : ''
            },
            clickMedia(elem) {
                var img = document.createElement('img')
                var width
                var height
                img.src = elem.url
                if (parseFloat(img.width) > parseFloat(img.height)) {
                    width = 720
                    height = ((parseFloat(img.height) + 30) * 720) / parseFloat(img.width)
                } else {
                    height = parseFloat(document.documentElement.clientHeight) - 50
                    width = (height * parseFloat(img.width)) / (parseFloat(img.height) + 30)
                }
                this.currentMedia = {
                    url: elem.url,
                    path: elem.path,
                    type: elem.type,
                    width: width,
                    height: height,
                }
                this.dialogMedia = true
            },
            getNextReview() {
                window.addEventListener('scroll', () => {
                    if (document.documentElement.scrollTop + (window.innerHeight ?? 0) >= document.documentElement.scrollHeight - 850 && this.countReviews < this.reviews.length) {
                        this.getMedia(this.countReviews, this.countReviews + 4)
                        this.countReviews += 5
                    }
                })
            },
            downloadMedia(url, path) {
                var name = path.split('/')
                name = name[name.length - 1]
                var link = document.createElement('a')
                link.setAttribute('href', url)
                link.setAttribute('download', name)
                link.setAttribute('target', 'blank')
                link.click()
                link = null
            },
            getNextReviewParsed() {
                window.addEventListener('scroll', () => {
                    if (document.documentElement.scrollTop + (window.innerHeight ?? 0) >= document.documentElement.scrollHeight - 850 && this.countReviewsParsed < this.filterReviewsParsed.length) {
                        this.countReviewsParsed += 6
                    }
                })
            },
            getReviewsParsed() {
                this.loading = true

                this.$store
                    .dispatch('reviews_parsed_get', {
                        dateStart: this.d1,
                        dateEnd: this.d2,
                        pointId: this.selectParsePoint.idString,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.reviewsParsed = res.data.reviewsParsed
                            for (let reviewParsed of this.reviewsParsed) {
                                if (reviewParsed.hashtags.length > 0) {
                                    reviewParsed.hashtags.forEach(hashtag => hashtag.replace('food', '#Еда').replace('delivery', '#Доставка'))
                                }
                            }

                            this.reviewsParsedByTime = this.reviewsParsed
                            this.filterReviewsParsed = this.reviewsParsedByTime

                            this.filtersParsed()
                            this.getChartsPie()
                        } else {
                            this.clearReviewsParsed()
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        this.clearReviewsParsed()
                    })
                    .finally(() => (this.loading = false))
            },
            getChartsPie() {
                let yandexReviews = this.reviewsParsedByTime.filter(r => r.type == 'Yandex')
                let googleReviews = this.reviewsParsedByTime.filter(r => r.type == 'Google')
                let tripadvisorReviews = this.reviewsParsedByTime.filter(r => r.type == 'Trip')
                let vlReviews = this.reviewsParsedByTime.filter(r => r.type == 'Vlru')
                let gisReviews = this.reviewsParsedByTime.filter(r => r.type == 'Dgis')
                let labels = ['5⭐', '4⭐', '3⭐', '2⭐', '1⭐']
                let colors = ['rgb(254, 154, 4)', 'rgb(11, 150, 24)', 'rgb(233, 30, 99)', 'rgb(51, 103, 204)', 'rgb(153, 1, 153)']

                if (yandexReviews && yandexReviews.length) {
                    let count = yandexReviews.reduce(
                        (p, c) => {
                            if (c.rating != 0 && c.rating != null) {
                                p.countPoint++
                                p.sumRating += c.rating
                            }
                            return p
                        },
                        { countPoint: 0, sumRating: 0 }
                    )
                    this.avgRatingYandex = count.countPoint ? (count.sumRating / count.countPoint).toFixed(2) : null
                    this.chartPieYandex = {
                        labels: labels,
                        datasets: [
                            {
                                label: labels,
                                data: [yandexReviews.filter(r => r.rating == 5).length, yandexReviews.filter(r => r.rating == 4).length, yandexReviews.filter(r => r.rating == 3).length, yandexReviews.filter(r => r.rating == 2).length, yandexReviews.filter(r => r.rating == 1).length],
                                backgroundColor: colors,
                                hoverOffset: 4,
                            },
                        ],
                    }
                } else {
                    this.avgRatingYandex = 0
                    this.chartPieYandex = {
                        labels: labels,
                        datasets: [
                            {
                                label: labels,
                                data: [],
                            },
                        ],
                    }
                }
                if (googleReviews && googleReviews.length) {
                    let count = googleReviews.reduce(
                        (p, c) => {
                            if (c.rating != 0 && c.rating != null) {
                                p.countPoint++
                                p.sumRating += c.rating
                            }
                            return p
                        },
                        { countPoint: 0, sumRating: 0 }
                    )
                    this.avgRatingGoogle = count.countPoint ? (count.sumRating / count.countPoint).toFixed(2) : null
                    this.chartPieGoogle = {
                        labels: labels,
                        datasets: [
                            {
                                label: labels,
                                data: [googleReviews.filter(r => r.rating == 5).length, googleReviews.filter(r => r.rating == 4).length, googleReviews.filter(r => r.rating == 3).length, googleReviews.filter(r => r.rating == 2).length, googleReviews.filter(r => r.rating == 1).length],
                                backgroundColor: colors,
                                hoverOffset: 4,
                            },
                        ],
                    }
                } else {
                    this.avgRatingGoogle = 0
                    this.chartPieGoogle = {
                        labels: labels,
                        datasets: [
                            {
                                label: labels,
                                data: [],
                            },
                        ],
                    }
                }
                if (tripadvisorReviews && tripadvisorReviews.length) {
                    let count = tripadvisorReviews.reduce(
                        (p, c) => {
                            if (c.rating != 0 && c.rating != null) {
                                p.countPoint++
                                p.sumRating += c.rating
                            }
                            return p
                        },
                        { countPoint: 0, sumRating: 0 }
                    )
                    this.avgRatingTripadvisor = count.countPoint ? (count.sumRating / count.countPoint).toFixed(2) : null
                    this.chartPieTripadvisor = {
                        labels: labels,
                        datasets: [
                            {
                                label: labels,
                                data: [
                                    tripadvisorReviews.filter(r => r.rating == 5).length,
                                    tripadvisorReviews.filter(r => r.rating == 4).length,
                                    tripadvisorReviews.filter(r => r.rating == 3).length,
                                    tripadvisorReviews.filter(r => r.rating == 2).length,
                                    tripadvisorReviews.filter(r => r.rating == 1).length,
                                ],
                                backgroundColor: colors,
                                hoverOffset: 4,
                            },
                        ],
                    }
                } else {
                    this.avgRatingTripadvisor = 0
                    this.chartPieTripadvisor = {
                        labels: labels,
                        datasets: [
                            {
                                label: labels,
                                data: [],
                            },
                        ],
                    }
                }
                if (vlReviews && vlReviews.length) {
                    let count = vlReviews.reduce(
                        (p, c) => {
                            if (c.rating != 0 && c.rating != null) {
                                p.countPoint++
                                p.sumRating += c.rating
                            }
                            return p
                        },
                        { countPoint: 0, sumRating: 0 }
                    )
                    this.avgRatingVlRu = count.countPoint ? (count.sumRating / count.countPoint).toFixed(2) : null
                    this.chartPieVlRu = {
                        labels: labels,
                        datasets: [
                            {
                                label: labels,
                                data: [vlReviews.filter(r => r.rating == 5).length, vlReviews.filter(r => r.rating == 4).length, vlReviews.filter(r => r.rating == 3).length, vlReviews.filter(r => r.rating == 2).length, vlReviews.filter(r => r.rating == 1).length],
                                backgroundColor: colors,
                                hoverOffset: 4,
                            },
                        ],
                    }
                } else {
                    this.avgRatingVlRu = 0
                    this.chartPieVlRu = {
                        labels: labels,
                        datasets: [
                            {
                                label: labels,
                                data: [],
                            },
                        ],
                    }
                }
                if (gisReviews && gisReviews.length) {
                    let count = gisReviews.reduce(
                        (p, c) => {
                            if (c.rating != 0 && c.rating != null) {
                                p.countPoint++
                                p.sumRating += c.rating
                            }
                            return p
                        },
                        { countPoint: 0, sumRating: 0 }
                    )
                    this.avgRating2gis = count.countPoint ? (count.sumRating / count.countPoint).toFixed(2) : null
                    this.chartPie2gis = {
                        labels: labels,
                        datasets: [
                            {
                                label: labels,
                                data: [gisReviews.filter(r => r.rating == 5).length, gisReviews.filter(r => r.rating == 4).length, gisReviews.filter(r => r.rating == 3).length, gisReviews.filter(r => r.rating == 2).length, gisReviews.filter(r => r.rating == 1).length],
                                backgroundColor: colors,
                                hoverOffset: 4,
                            },
                        ],
                    }
                } else {
                    this.avgRating2gis = 0
                    this.chartPie2gis = {
                        labels: labels,
                        datasets: [
                            {
                                label: labels,
                                data: [],
                            },
                        ],
                    }
                }
            },
            onClickReviewParsed(event, row) {
                this.selectedItemParsed = event
                this.dialogParseReview = true
            },
            filterReset() {
                this.filterReviewsParsed = this.reviewsParsedByTime
                this.platformSelected = '< Все >'
            },
            getImgReviewById(id) {
                for (let review of this.reviewsParsed) {
                    if (review.id === id) {
                        return review.img
                    }
                }
                return null
            },
            async reviewParserSave() {
                if (!this.currentReviewParser.id && this.selectParsePoint) {
                    this.currentReviewParser.name = this.selectParsePoint.name
                    this.currentReviewParser.pointId = this.selectParsePoint.idString
                }

                this.currentReviewParser.tagsPass = this.currentReviewParserTagsPass ? this.currentReviewParserTagsPass.split(',').map(str => str.trim()) : []
                this.currentReviewParser.tagsSkip = this.currentReviewParserTagsSkip ? this.currentReviewParserTagsSkip.split(',').map(str => str.trim()) : []
                this.currentReviewParser.startNow = true

                this.$store
                    .dispatch('review_parser_set', this.currentReviewParser)
                    .then(res => {
                        if (!res.data.success) {
                            this.notifyToast(`Ошибка сохранения. Попробуйте снова. (${res.data.msg})`, { color: 'error', timeout: 10000 })
                        } else {
                            this.notifyToast('Настройки сохранены успешно.', { color: 'success', timeout: 4000 })
                        }
                    })
                    .catch(err => {
                        this.notifyToast('Ошибка сохранения. Проверьте настройки сети и попробуйте снова.', { color: 'error', timeout: 10000 })
                        console.error(err)
                    })
                this.updateData()
            },
            changeParsingMode() {
                if (this.currentReviewParser) {
                    this.currentReviewParser.isEnabled = !this.currentReviewParser.isEnabled
                    this.reviewParserSave()
                } else {
                    this.notifyToast('Не удалось найти парсер', { color: 'error', timeout: 10000 })
                }
            },
            async selectPointReviewParsed() {
                if (this.points.length) {
                    this.selectParsePoint = this.points.find(point => point.idString === this.selectParsePointId)

                    if (this.selectParsePoint) {
                        const reviewsRes = await this.$store.dispatch('review_parsers_get', this.selectParsePointId)

                        if (reviewsRes?.data?.success && reviewsRes.data.reviewParsers?.length) {
                            this.currentReviewParser = reviewsRes.data.reviewParsers[0]
                        } else {
                            this.currentReviewParserReset()
                        }
                    } else {
                        this.currentReviewParserReset()
                    }

                    this.currentReviewParserTagsPass = this.currentReviewParser.tagsPass.join(', ')
                    this.currentReviewParserTagsSkip = this.currentReviewParser.tagsSkip.join(', ')
                    this.getReviewsParsed()
                }
            },
            currentReviewParserReset() {
                this.currentReviewParser = {
                    isEnabled: false,
                    startNow: false,
                    name: '',
                    tagsPass: [],
                    tagsSkip: [],
                    isLocalTime: true,
                    urlYandex: '',
                    urlGoogle: '',
                    urlTrip: '',
                    urlVlru: '',
                    urlDgis: '',
                    isEnabledYandex: false,
                    isEnabledGoogle: false,
                    isEnabledTrip: false,
                    isEnabledVlru: false,
                    isEnabledDgis: false,
                }
            },
            clearReviewsParsed() {
                this.reviewsParsed = []
                this.filterReviewsParsed = []
                this.reviewsParsedByTime = []
                this.getChartsPie()
            },
            updateParsedReviewTable() {
                this.getReviewsParsed()

                this.reviewsParsed = []
                this.filterReviewsParsed = []
                this.reviewsParsedByTime = []
            },
        },
    }
</script>

<style lang="stylus">
    .flex
        display: flex
        justify-content: flex-end

    @media (max-width: 960px) {
        .card-center {
            justify-content: center;
        }
    }

    .no-data
        font-weight:bold
        color:#999
        font-size:1.2rem

    .rating-text
        font-weight:800
        font-size:2.7rem

    .pie-wrapper
        position: relative;

    .center-wrapper
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;

    .piechartZ
        position: relative;
        z-index: 2 !important;
        background-color: transparent !important;

    .card-pie
        width: 260px !important;
        height: 230px !important;

    .pos-rel
        position:relative

    .audio-content__info
        display: flex
        margin: 10px 0 5px 0

    .audio-content__info_duration
        background-color: rgba(71, 63, 137, 0.1)
        padding: 2px 5px
        border-radius: 5px
        margin-bottom: 0 !important

    .audio-content__info_type
        display: flex
        align-items: center
        margin-right: 5px
        margin-bottom: 0 !important
        color: #595959;

    .audio-content__text
        color: #595959
        line-height: 140%

    .reviews
        height 100%
        padding-top 10px !important

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    padding 0 8px
                    white-space nowrap

            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                td
                    white-space nowrap
                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px
                    td.tooltip-text
                        padding-left 0
                    td
                        padding-left 0

        .v-tooltip__content
            background #222
            color #fff
            border-radius 6px
            padding 5px 10px 4px
            &:hover
                cursor pointer

        .v-chip
            cursor pointer
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                // cursor pointer
                padding 0 6px

        .v-chip.v-size--x-small
            height 18px

        b
            font-weight 500

        .guest-error
            color #F44336

        .guest-selectable
            font-weight 400
            color #0D47A1
            cursor pointer
            &:hover
                text-decoration: underline

    .v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea
        margin 10px
        padding 0

    .menu_text
        font-size: 13px
    audio
        width 80%
        height 35px
        margin-top 5px
    .v-slide-group__next, .v-slide-group__prev
        min-width 20px
    .v-dialog > .v-card > .v-card__text
        padding 0 24px 0px
    .v-dialog
        overflow hidden
    audio::-webkit-media-controls-timeline-container
        color gray
    audio::-webkit-media-controls-timeline
        color gray
</style>
